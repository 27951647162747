.booking-cancel-form {
  .reason-type {
    font-size: 16px;
  }
  .reason-note {
    margin-left: 24px;
    white-space: pre-wrap;
  }
  input.ant-radio-input {
    pointer-events: initial;
  }
  .no-visit-reason-hidden {
    display: none;
  }
    .ant-form-item-explain{
      padding-top: 16px;
    }
}

.booking-detail {
  .row-divider {
    margin: 16px 0;
  }
  .booking-detail-row {
    margin-top: 8px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.ipn-input {
  position: relative;
}

.ipn-input-suffix {
  content: '';
  font-family: sans-serif;
  width: fit-content;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 27px;
  transform: translateY(-50%);
}

.content-wrapper,
.deadline-time {
  display: flex;
  span {
    display: block;
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.booking-item-wrapper {
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }
}

#booking-notification-list {
  height: 600px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  .notification-loading-container {
    position: absolute;
    bottom: 40px;
    width: 100%;
    text-align: center;
  }

  .notification-list {
    .booking-notification-wrapper {
      cursor: pointer;
      border-bottom: 1px solid #f0f0f0;
    }
    .booking-notification-read {
      background-color: #f9f3e1;
      cursor: pointer;
    }

    .treatment-time {
      span {
        margin-right: 5px;
      }
    }
    .booking-notification-status {
      min-width: 130px;
    }
    .timeline {
      font-size: small;
      margin-top: 4px;
    }
  }
}

#button-confirm-on-time {
  button:first-child{
    margin-right: 4px;
  }
  .active {
    color: #fff;
    background-color: #e5004e;
    border-color: #e5004e;
  }
  >:not(.active) {
    &:hover {
      color: #e5004e;
      border-color: #e5004e;
    }
  }
  .ant-btn {
    font-size: 11px;
    border-radius: 20px;
  }
}

@primary-color: #0f99fa;@border-radius-base: 4px;