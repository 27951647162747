.payment-detail-form{

  .payment-detail-row {
    &:first-child {
      margin-top: 0;
    }
  }

disabled {
    background-color: #141414 !important;
  }
  .status-detail {
      width: 150px;
      border-radius: 15px;
      text-align: center;
  }
  .status-detail-1 {
    background-color: #C5EABF!important;
    white-space: nowrap;
    padding: 2px 12px;
  }
  .status-detail-2 {
    padding: 2px 12px;
    background-color: #BCDCED!important;
  }
  .status-detail-3 {
    padding: 2px 12px;
    background-color: #F2473E!important;
  }
  .status-detail-4 {
    padding: 2px 12px;
    background-color: #D5D4D4!important;
  }
  .status-detail-5 {
    padding: 2px 12px;
    background-color: #F4D0D5!important;
  }
}

.payment-billing-form {
  .billing-card-info {
    width: 360px;
    height: auto; 
    border-radius: 14px; 
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6)
  }
  .disabled {
    color:rgba(0, 0, 0, 0.25) !important;
  }
}



@primary-color: #0f99fa;@border-radius-base: 4px;