@import '~antd/lib/style/themes/default.less';

.salon-form {
  .ant-form-item-has-error.ant-form-item-explain,
  .ant-form-item-has-error.ant-form-item-split {
    font-size: 10px;
    font-weight: 600;
    margin-left: 24px;
    margin-bottom: 10px;
  }

  .salon-schedule-error {
    font-size: 10px;
    font-weight: 600;
    color: #ff4d4f;
  }

  .day-of-week {
    font-weight: bold;

    &.sat {
      color: @blue-7;
    }

    &.sun {
      color: @red-6;
    }
  }
}
.emails-table {
  width: 700px;
  .ant-table-thead {
    display: none;
  }
  .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
  }
}

// Connections with third parties
#connection-container {
  display: flex;
  margin-top: 24px;

  .beauty-merit-connection {
    border: 1px solid #d9d9d9;
    margin-right: 32px;
  }
  .coming-soon-connection {
    border: 1px solid #d9d9d9;
  }

  .connect-3rd-party-banner {
    padding: 32px 16px;
    border-radius: 16px;
    position: relative;
    width: 274px;
    .connected-parties{
      position: absolute;
      top: 16px;
      right: 16px;
      text-transform: uppercase;
      color: #52c41a;
      .connected-text {
        margin-right: 5px;
      }
    }
  }
}
#salonConnectStaffForm {
  .column-title-form {
    display: flex;
    width: 700px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 16px 16px;
    background-color: #fafafa;
    margin-top: 16px;
    border: 1px solid #f0f0f0;
    .column-title-staff-salon{
      width: 450px;
    }
    .column-title-cs-staff{
      width: 250px;
    }
  }
  .staff-row-info {
    align-items: center !important;
    border: 1px solid #f0f0f0;
    border-width: 0px 1px 1px 1px;
    padding: 12px 16px;
    width: 700px;
    #select-cs-staff {
      .ant-form-item {
        margin-bottom: unset;
      }
    }
  }
  .submit-section-on-screen {
    margin-top: 24px;
    width: 700px;
    text-align: center;
  }
  .submit-section-popup {
    text-align: right;
  }
}
.connect-control-modal{
    .ant-modal-content{
      white-space: pre-line;
    }
  }
@primary-color: #0f99fa;@border-radius-base: 4px;