.mysalon-layout {
    // min-width: 1200px;
    min-width: 100vh;

    .sider {
        z-index: 100;
        min-height: 100vh;
        background: #fff;
        box-shadow: 
        0 10px 16px 0 rgba(0, 0, 0, 0.2), 
        0px 0px 0px 0 rgba(0, 0, 0, 0.19) !important;
        
        .logo-wrapper {
          display: flex;
          .logo {
            margin: 16px auto;
            height: 40px;
          }  
        }
         .salon-name {
            text-align: center;
            font-weight: bold;
            margin: 0px 8px;  
          }
        .ant-menu-inline {
         border-right: 0;
        }
        display: flex;
        justify-content: space-between;
        .ant-layout-sider-children {
            width: 100%;
        } 
        .sider-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 100vh;

          .sider-bottom {
            // margin-bottom: 8px;
            // margin-left: 16px;
            padding: 8px 16px;

            .sider-bottom-lang {
              margin: 0 8px;
            }

            .sider-bottom-account {
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;

              .sider-bottom-version {
                flex-grow: 1;
                text-align: right;
                color: gray;
                font-size: small;
              }

              .sider-bottom-setting {
                padding: 4px;
                font-size: 20px;
              }
            }
          }
        }
    }
}
// Adjust the Alert message more bigger.
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      font-size: 22px;
      padding: 18px 40px;
    }
  }
  .anticon {
    font-size: 24px;
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;