body {
  background-color: #f5f6fa!important;
}

.complete-profile-page {
  padding: 64px 0;

  .ant-card.ant-card-bordered {
    width: 900px;
    margin: 0 auto !important;
    border-radius: 10px !important;
  }

  .request-logo {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .request-title {
      color: #666666;
      margin-top: 10px;
    }
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;