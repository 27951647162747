#staff-schedule-daily {
  .highlighted {
    background-color: #bcdced !important;
  }

  .ant-table-tbody > tr > td {
    padding: 0px 0px;
    overflow-wrap: break-word;
  }

  .booking-tag {
    opacity: 0.93;
    text-align: left;
    .booking-cell {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .booking-tag-child {
      &.booking-tag-customer {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.booking-tag-menu {
        color: white !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .booking-cell-span {
        background-color: #EF5FA7;
        padding: 4px 8px;
        border-radius: 5px;
        margin-right: 6px;
      }
    }
  }

  #schedule-table-all-staff {
    .ant-table-cell.ant-table-cell-fix-left {
      z-index: 1000 ;
    }
    td {
      cursor: pointer;
    }

    td:hover{
      z-index: 100;
      transition: width 2s;
      .booking-tag{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
        width: fit-content !important;
        min-width: 100%;
        max-width: unset !important;
      }
    }
  }

  .sales-card {
    .ant-card-body {
      padding: 4px 8px;
      min-width: 110px;
    }
  }
  .ant-btn {
    padding: 4px 8px;
  }
  #button-notification {
    .ant-btn {
      border-color: #d9d9d9;
      color: gray;
    }
  }
  #button-waiting-done {
    .ant-btn-dangerous {
      border-color: #ff9999;
      color: #f2494e;
      background-color: #fdf1f0;
      animation: glowing 800ms infinite !important;
    }
    @keyframes glowing {
      0% {
        box-shadow: 0 0 3px #ff9999;
      }
      50% {
        box-shadow: 0 0 12px #ff9999;
      }
      100% {
        box-shadow: 0 0 3px #ff9999;
      }
    }
  }
}

@primary-color: #0f99fa;@border-radius-base: 4px;