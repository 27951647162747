@import '~antd/lib/style/themes/default.less';

.staff-schedules {
  .ant-table-tbody > tr > td {
    padding: 0px 0px;
    overflow-wrap: break-word;
  }
    
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: unset;
  }

  .day-of-week {
    font-weight: bold;
  
    &.sat {
      color: @blue-7;
    }
  
    &.sun {
      color: @red-6;
    }
  }
  
  .cell {
    height: 80px;
    border-bottom: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.disable {
      background-color: #fafafa!important;
      color: rgba(0,0,0,.85);
      font-weight: 500;
    }

    &.absent {
      background-color: #FF7FAC!important;
      color: rgba(0,0,0,.85);
      font-weight: 500;
    }
  }
}

.outside-booking-list {
  margin-top: 8px;

  .outside-booking-item {
    margin-bottom: 4px;
    display: flex;

    .outside-booking-item-id {
      color: #06adef;
      text-decoration: underline;
      margin-right: 8px;
      cursor: pointer;
    }
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;