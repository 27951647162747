.sider-layout-background {
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
}

.bookings-page {
    .title-tab {
        span {
            display: block;
        }
    }

    // .ant-table-tbody > tr > td {
    //     padding: 6px 6px;
    //     overflow-wrap: break-word;
    // }
      
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      cursor: pointer;
    }
  
    .cell {
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
  
      &.booking-requested {
        background-color: #BCDCED!important;
        color: rgba(0,0,0,.85);
        font-weight: 500;
      }
  
      &.booking-comfirmed {
        background-color: #C5EABF!important;
        color: rgba(0,0,0,.85);
        font-weight: 500;
      }
  
      &.booking-waittingDone {
        background-color: #EFC4D3!important;
        color: rgba(0,0,0,.85);
        font-weight: 500;
      }
  
      &.booking-done {
        background-color: #FF7FAC!important;
        color: rgba(0,0,0,.85);
        font-weight: 500;
      }
    }
  
    .system {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
  
      &.nailie {
        background-color: #E5004E;
      }
    }
  
    // .cell > div:hover{
    //   background-color: #E5004E;
    // }
  
    // .ant-table-body > table > tbody > tr > td:hover, 
    // .ant-table-body > table > tbody > tr > td:hover{
    //   background-color: #E5004E!important; // Or any colour you like
    //   border-width: 1px;
    // }
  
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      height: auto;
      line-height: initial;
    }

    .status-block {
      width: 100px;
      border-radius: 15px;
      text-align: center;
      background-color: #BCDCED;
  
      &.new {
        background-color: #d9d9d9;
      }
  
      &.cancel {
        background-color: #BCDCED!important;
      }

      &.arrived {
        background-color: #C5EABF!important;
      }

      &.started {
        background-color: #EFC4D3!important;
      }

      &.done {
        
        background-color: #FF7FAC!important;
      }
      
    }
  }

.ant-input-number {
  width: 190px;
}
@primary-color: #0f99fa;@border-radius-base: 4px;