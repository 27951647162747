body {
  background-color: #f5f6fa!important;
}

.login-page {
  width: 300px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);

  .login-page-row {
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #fff;
    padding-left: 20px;
    padding-right: 20px;
  }

  .login-logo {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .login-title {
      color: #666666;
      margin-top: 10px;
    }
  }

  .login-button {
    margin-bottom: 30px;
  }
  .login-form-forgot {
    float: left;
  }
  .login-form-request {
    text-align: center;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;