.sider-layout-background {
  background: #fff;
  margin-top: 20px;
  margin-left: 20px;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.staff-member-page {
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    // cursor: pointer;
  }
  .hidden-column {
    display: none;
  }
  .external-party-column {
    white-space: nowrap;
  }
}

@primary-color: #0f99fa;@border-radius-base: 4px;