.service-page {
    
}

#add-menu-btn{
  position: fixed;
  right: 24px;
  z-index: 1;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;;
}
@primary-color: #0f99fa;@border-radius-base: 4px;