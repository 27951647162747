.salon-account-page {
  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
    font-size: 10px;
    font-weight: 600;
  }
  .gutter-row {
      margin-bottom: 20px;
  }

  span {
      display: inline-block;
  }

  .title {
    width: 30%;
    margin-right: 16px;
    text-align: right;
    color: #a7a7a7;
  }

  .title-left {
      width: 25%;
      margin-right: 16px;
      text-align: right;
      color: #a7a7a7;
  }

  .title-right {
    width: 45%;
    margin-right: 16px;
    text-align: right;
    color: #a7a7a7;
  }

  .status-block {
      width: 160px;
      border-radius: 15px;
      text-align: center;
      background-color: #BCDCED;
  
      &.waiting {
        background-color: #BCDCED!important;
      }

      &.confirmed {
        background-color: #C5EABF!important;
      }

      &.reject {
        
        background-color: #FF7FAC!important;
      }
  }

  .ant-radio {
    vertical-align: top;
    margin-top: 4px;
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;