.ant-upload.ant-upload-select-picture-card {
  width: 108px;
  height: 108px;
  margin: 0;
  border-radius: 8px;

  .upload-button {
    width: 106px;
    height: 106px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .anticon-plus {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.43);
    }

    div {
      margin-top: 12px;
      font-size: 12px;
    }
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;