@import '~antd/lib/style/themes/default.less';

.week-form {
  .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
    font-size: 10px;
    font-weight: 600;
    margin-left: 24px;
    margin-bottom: 10px;
  }

}

.day-of-week {
  font-weight: bold;

  &.sat {
    color: @blue-7;
  }

  &.sun {
    color: @red-6;
  }
}
@primary-color: #0f99fa;@border-radius-base: 4px;