.staff-detail-page {
    .gutter-row {
        margin-bottom: 20px;
    }

    span {
        display: inline-block;
    }

    .title {
        width: 35%;
        margin-right: 16px;
        text-align: right;
        color: #a7a7a7;
    }

    .status-block {
        width: 160px;
        border-radius: 15px;
        text-align: center;
        background-color: #BCDCED;
    
        &.waiting {
          background-color: #BCDCED!important;
        }
  
        &.confirmed {
          background-color: rgb(54, 178, 126) !important;
        }
  
        &.reject {
          
          background-color: #FF7FAC!important;
        }
    }
    .profile-dashboard-services {
    margin-bottom: 60px;

    .service-select-list {
      padding-top: 16px;
      margin-top: -20px;
    }
    .service-title {
      // style={{ backgroundColor: '#f5f5f9', paddingTop: 20, paddingLeft: 20, marginBottom: 20, paddingBottom: 20 }}
      background-color: #f5f5f9;
      padding-top:20px;
      padding-left: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    .service-item {
      .service-duration {
        margin-left: 12px;
        color: #a7a7a7;
     }
      .service-photo {
        margin-left: 8px;
    }
      .service-name {
        margin-left: 12px;
      }
      .service-price {
        width: 200px;
      }
    
      .service-recommended {
        text-align: right;
        border: 1px solid #FAAE31;
        padding: 2px 12px;
        border-radius: 14px;
        background-color: #FCF9DF;
        white-space: nowrap;
        .service-recommended-icon{
          margin-right: 4px;
          color: #FAAE31;
        }
        .service-recommended-text {
          color: #FAAE31;;
        }
      }
    }
  }
    
}
@primary-color: #0f99fa;@border-radius-base: 4px;