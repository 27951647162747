@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN',
  '游ゴシック', '游ゴシック体', YuGothic, 'Yu Gothic', 'メイリオ', Meiryo, 'ＭＳ ゴシック', 'MS Gothic', HiraKakuProN-W3,
  'TakaoExゴシック', TakaoExGothic, 'MotoyaLCedar', 'Droid Sans Japanese', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-version {
  position: absolute;
  top: 4px;
  right: 0;
  text-align: right;
  z-index: 100  ;
  left: auto;
  padding: 0 1rem;
  color: gray;
  background-color: white;
  font-size: small;
}

.center-full-screen-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-design-table-row {
  cursor: pointer;
}

.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: unset !important;
}

@primary-color: #0f99fa;@border-radius-base: 4px;